<template>
  <div>
    <v-card
      :loading="carregandoBuscar"
    >
      <template slot="progress">
        <v-progress-linear
          color="grey"
          indeterminate
        />
      </template>
      <v-toolbar
        color="primary"
        flat
        dense
      >
        <v-toolbar-title class="white--text font-weight-black">
          # {{ ocorrencia.id || '--' }}
        </v-toolbar-title>
        <v-spacer />
        <v-col
          cols="8"
          md="3"
          class="pa-0"
        >
          <v-select
            v-model="visibilidadeId"
            dark
            :value="1"
            :items="visibilidades"
            item-text="descricao"
            item-value="id"
            outlined
            dense
            hide-details
            prepend-icon="mdi-eye"
            single-line
            :loading="carregandoBuscarVisibilidades"
          />
        </v-col>
      </v-toolbar>

      <v-tabs
        v-model="tab"
        :vertical="!$vuetify.breakpoint.mobile"
        icons-and-text
        dense
        background-color="blue-grey lighten-5"
      >
        <v-tab key="ocorrencia">
          Ocorrência
          <v-icon>
            mdi-book-information-variant
          </v-icon>
        </v-tab>
        <v-tab
          key="acompanhamentos"
          :disabled="(typeof ocorrencia.id === 'undefined')"
        >
          Acompanhamentos
          <v-icon>
            mdi-bell
          </v-icon>
        </v-tab>

        <v-tabs-items
          v-model="tab"
          :vertical="!$vuetify.breakpoint.mobile"
        >
          <v-tab-item
            key="ocorrencia"
            style="transition:none !important;"
          >
            <tab-ocorrencia
              :validacao-formulario="validacaoFormularioOcorrencia"
              :carregando-salvar="carregandoSalvar"
              :ocorrencia="ocorrencia"
              @salvar="salvarOcorrencia"
            />
          </v-tab-item>
          <v-tab-item
            key="acompanhamento"
            style="transition:none !important;"
          >
            <tab-acompanhamentos
              :ocorrencia="ocorrencia"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
  import ocorrenciasApi from '@/api/ocorrencias'

  export default {
    components: {
      TabOcorrencia: () => import('@/views/pages/ocorrencias/tabs/ocorrencia/OcorrenciasTabsOcorrencia'),
      TabAcompanhamentos: () => import('@/views/pages/ocorrencias/tabs/acompanhamentos/OcorrenciasTabsAcompanhamentos'),
    },

    props: {
      validacaoFormularioOcorrencia: {
        type: Object,
        default: () => { return {} },
      },
      carregandoSalvar: {
        type: Boolean,
        default: false,
      },
      carregandoBuscar: {
        type: Boolean,
        default: false,
      },
      ocorrencia: {
        type: Object,
        default: () => { return {} },
      },
    },

    data () {
      return {
        tab: 'ocorrencia',
        carregandoBuscarVisibilidades: false,
        visibilidades: [],
        visibilidadeId: 1,
      }
    },

    watch: {
      ocorrencia (val) {
        if (!val) return

        this.visibilidadeId = val.visibilidade_id
      },
    },

    mounted () {
      this.buscarVisibilidades()
    },

    methods: {
      async buscarVisibilidades () {
        try {
          this.carregandoBuscarVisibilidades = true
          const resposta = await ocorrenciasApi.buscarVisibilidades()
          this.visibilidades = resposta.data.data
        } catch (e) {
          console.log(e)
        } finally {
          this.carregandoBuscarVisibilidades = false
        }
      },

      async salvarOcorrencia (ocorrencia) {
        const dados = { ...ocorrencia }

        dados.data_ocorrencia = dados.data + ' ' + dados.hora + ':00'
        dados.visibilidade_id = this.visibilidadeId

        delete dados.data
        delete dados.hora

        this.$emit('salvar', dados)
      },
    },
  }
</script>
